import { mergeOverrides, useStyletron } from "baseui";
import { borderShadow, expandBorderWidth, paddingUtil, paddingVertical } from "../../utils";
// eslint-disable-next-line no-restricted-imports
import type { ButtonProps as BaseUIButtonProps, ButtonOverrides } from "baseui/button";
// eslint-disable-next-line no-restricted-imports
import { Button as RawButton, SIZE } from "baseui/button";
import { merge, omit } from "lodash";
import type { RefAttributes } from "react";
import React, { Children, forwardRef } from "react";
import type { StyleObject } from "styletron-standard";
import { KeyNormalizedControl } from "../../utils/KeyNormalizedControl";
import { useWidgets } from "../contexts";
// eslint-disable-next-line no-restricted-imports
export { SIZE as ButtonSize, StyledLoadingSpinner } from "baseui/button";
export type { ButtonOverrides } from "baseui/button";

export const ButtonHoverSelector = ":hover:not(:disabled):not(:active)";

export type ButtonProps = Omit<BaseUIButtonProps, "kind" | "size"> & {
  kind?: keyof typeof ButtonKind;
  size?: SIZE[keyof SIZE] | "tight" | "square";
  tabIndex?: number;
  className?: string;
  "data-testid"?: string;
  normalizeKeys?: boolean;
  autoFocus?: boolean;
  draggable?: boolean;
  $pressed?: boolean;
} & RefAttributes<HTMLButtonElement>;

/**
 * BaseUI reexport for minimal buttons because we need to pass overrides to style the text right. Wherever you want to use a <Button />, you should use this instead.
 * See https://github.com/uber/baseweb/issues/106 for more information
 */

export const ButtonKind = {
  primary: "primary",
  secondary: "secondary",
  tertiary: "tertiary",
  minimal: "minimal",
  danger: "danger",
  delete: "delete",
  tertiaryDestructive: "tertiaryDestructive",
} as const;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const [_css, $theme] = useStyletron();
  const rawProps: BaseUIButtonProps = {};
  const { disableButtons } = useWidgets();
  const disabled = props.disabled ?? disableButtons?.(props);

  const kind = props.kind ?? "primary";

  const rawSize: SIZE[keyof SIZE] | undefined = props.size == "tight" ? SIZE.compact : props.size == "square" ? SIZE.default : props.size;

  let overrides: ButtonOverrides = {
    BaseButton: {
      style: {
        cursor: disabled ? "not-allowed !important" : "pointer",
        transitionProperty: "background, background-color",
        transitionDuration: "0s",
        ":focus-visible": {
          outline: `2px solid ${$theme.colors.borderFocus}`,
          outlineOffset: "1px",
        },
        userSelect: "none",
      },
    },
    StartEnhancer: {
      style: {
        marginRight: $theme.sizing.scale300,
      },
    },
    EndEnhancer: {
      style: {
        marginLeft: $theme.sizing.scale300,
      },
    },
  };

  switch (props.size) {
    case "tight": {
      merge(overrides, {
        BaseButton: {
          style: paddingUtil($theme.sizing.scale0),
        },
      });
      break;
    }
    case SIZE.large: {
      merge(overrides, {
        BaseButton: {
          style: {
            ...paddingVertical($theme.sizing.scale300),
            ...$theme.typography.body,
          },
        },
      });
      break;
    }
    case undefined:
    case SIZE.compact:
    case SIZE.default: {
      merge(overrides, {
        BaseButton: {
          style: paddingUtil($theme.sizing.scale200, $theme.sizing.scale500),
        },
        StartEnhancer: {
          style: {
            marginRight: $theme.sizing.scale400,
          },
        },
      });
    }
  }

  const activeStyle = {
    backgroundColor: "transparent",
    boxShadow: "none",
    textDecoration: "underline",
  };

  const style: Record<string, StyleObject> = {
    primary: {
      backgroundColor: $theme.colors.primary700,
      boxShadow: borderShadow($theme.colors.neutralMax, 1, $theme.lighting.shadowButtonPrimary),
      ...expandBorderWidth(0),
      ":active:not(:disabled)": {
        boxShadow: borderShadow($theme.colors.neutralMax, 0, $theme.lighting.shadowButtonPrimaryInset),
      },
      ":active:not(:disabled) > *": {
        transform: "translateY(1px)",
      },
      ":disabled": {
        backgroundColor: $theme.colors.alpha300,
        color: $theme.colors.primary50,
        boxShadow: "none",
      },
    },
    secondary: {
      color: $theme.colors.black,
      boxShadow: borderShadow($theme.colors.primary300, 1, $theme.lighting.shadowButton),
      ...expandBorderWidth(0),
      backgroundColor: $theme.colors.neutralMin,
      ":active:not(:disabled)": {
        boxShadow: borderShadow($theme.colors.primary300, 1, $theme.lighting.shadowButtonInset),
        backgroundColor: $theme.colors.primary50,
      },
      ":active:not(:disabled) > *": {
        transform: "translateY(1px)",
      },
      ":disabled": {
        backgroundColor: $theme.colors.alpha50,
        boxShadow: "none",
      },
    },
    tertiary: {
      ...paddingUtil("0px"),
      color: props.size == SIZE.compact ? $theme.colors.linkText : $theme.colors.contentLinkText,
      textDecoration: $theme.typography.contentLink?.textDecoration ?? "none",
      backgroundColor: "transparent",
      textUnderlineOffset: "3px",
      ...expandBorderWidth(0),
      ":hover:not(:disabled)": {
        ...activeStyle,
      },
      ":hover:not(:disabled):not(:active)": {
        ...activeStyle,
        color: props.size == SIZE.compact ? $theme.colors.linkHover : $theme.colors.contentLinkHover,
      },
      ":focus-visible": {
        ...activeStyle,
        color: props.size == SIZE.compact ? $theme.colors.linkHover : $theme.colors.contentLinkHover,
        outlineOffset: "3px",
      },
      ":disabled": {
        color: $theme.colors.alpha400,
        backgroundColor: "transparent",
      },
      ":active:not(:disabled)": {
        ...activeStyle,
        color: props.size == SIZE.compact ? $theme.colors.linkActive : $theme.colors.contentLinkActive,
        transform: "perspective(500px) translate3d(0, 0, -20px)",
      },
    },
    danger: {
      backgroundColor: $theme.colors.red500,
      boxShadow: borderShadow($theme.colors.red600, 1, $theme.lighting.shadowButtonPrimary),
      ":hover:not(:disabled):not(:active)": {
        backgroundColor: $theme.colors.red400,
        boxShadow: borderShadow($theme.colors.red500, 1, $theme.lighting.shadowButtonPrimary),
      },
      ":active:not(:disabled)": {
        backgroundColor: $theme.colors.red400,
        boxShadow: borderShadow($theme.colors.red400, 1, $theme.lighting.shadowButtonPrimaryInset),
      },
      ":active:not(:disabled) > *": {
        transform: "translateY(1px)",
      },
      ":disabled": {
        boxShadow: "none",
        border: `1px solid ${$theme.colors.primary100}`,
      },
    },
    delete: {
      color: $theme.colors.red500,
      backgroundColor: $theme.colors.neutralMin,
      boxShadow: borderShadow($theme.colors.red200, 1, $theme.lighting.shadowButton),
      ":hover:not(:disabled):not(:active)": {
        backgroundColor: $theme.colors.red50,
        boxShadow: borderShadow($theme.colors.red300, 1, $theme.lighting.shadowButton),
      },
      ":active:not(:disabled)": {
        backgroundColor: $theme.colors.red50,
        boxShadow: borderShadow($theme.colors.red400, 1, $theme.lighting.shadowButtonInset),
      },
      ":active:not(:disabled) > *": {
        transform: "translateY(1px)",
      },
      ":disabled": {
        boxShadow: "none",
        color: $theme.colors.primary50,
        backgroundColor: $theme.colors.primary300,
        border: `1px solid ${$theme.colors.primary300}`,
      },
    },
    tertiaryDestructive: {
      ...paddingUtil("0px"),
      color: $theme.colors.red500,
      backgroundColor: "transparent",
      ":hover:not(:disabled)": {
        backgroundColor: "transparent",
        boxShadow: "none",
      },
      ":hover:not(:disabled):not(:active)": {
        backgroundColor: "transparent",
        boxShadow: "none",
        color: $theme.colors.red600,
      },
      ":focus-visible": {
        backgroundColor: "transparent",
        boxShadow: "none",
        color: $theme.colors.red600,
        outlineOffset: "3px",
      },
      ":active:not(:disabled)": {
        backgroundColor: "transparent",
        color: $theme.colors.red700,
        transform: "perspective(500px) translate3d(0, 0, -20px)",
      },
    },
  };

  merge(overrides, {
    BaseButton: {
      style: style[kind],
    },
  });

  overrides = mergeOverrides(overrides as any, props.overrides as any);

  if (props.$style) {
    merge((overrides.BaseButton as any).style, props.$style);
  }

  if (props.$pressed || props.isSelected) {
    const style = (overrides.BaseButton as any)?.style as StyleObject | undefined;
    if (style?.[":active:not(:disabled)"]) {
      merge((overrides.BaseButton as any).style, style[":active:not(:disabled)"]);
    }
  }

  // normalizeKeys is not a valid prop for Button
  merge(rawProps, omit(props, ["children", "normalizeKeys"]));

  // wrap any child that is a raw string in a wrapper span, so that the CSS selectors which translate the children on active can target them. children that are already an element and not a string aren't wrapped to keep things as unchanged as possible
  // eslint-disable-next-line react/jsx-key
  const children = Children.map(props.children, (child) => {
    return typeof child === "string" ? (
      <span style={{ whiteSpace: (overrides.BaseButton as any)?.style?.whiteSpace ?? "pre-wrap" }}>{child}</span>
    ) : (
      child
    );
  });

  const basewebKind = kind == "delete" ? "secondary" : kind == "danger" ? "primary" : kind == "tertiaryDestructive" ? "tertiary" : kind;

  const button = (
    <RawButton
      ref={ref}
      {...(rawProps as any)} // `as any` Needed to control properties that exist, but are not in BaseUIButtonProps. Ex:`draggable`
      disabled={disabled}
      overrides={overrides}
      kind={basewebKind}
      size={rawSize}
      draggable={props.draggable ? true : false}
    >
      {children}
    </RawButton>
  );

  return props.normalizeKeys ? (
    <KeyNormalizedControl mode="simple" ignoreKeyProps={{ style: { lineHeight: "0" } }}>
      {button}
    </KeyNormalizedControl>
  ) : (
    button
  );
});
